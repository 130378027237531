.button-container {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

button,
.button,
a.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  margin-bottom: 5px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid transparent;
  appearance: none;
  cursor: pointer;
  outline: none;
  // Default
  background: $light-background-header;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
    color: inherit;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
    opacity: 0.6;
  }

  [data-theme=light] & {
    background: $light-background-header;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  &.outline {
    background: transparent;
    box-shadow: none;
    padding: 8px 18px;
    // Default
    border-color: $light-background-secondary;

    @media (prefers-color-scheme: dark) {
      border-color: $dark-background-secondary;
      color: inherit;
    }
  
    @media (prefers-color-scheme: light) {
      border-color: $light-background-secondary;
    }
  
    [data-theme=dark] & {
      border-color: $dark-background-secondary;
      color: inherit;
    }
  
    [data-theme=light] & {
      border-color: $light-background-secondary;
    }

    :hover {
      transform: none;
      box-shadow: none;
    }
  }

  &.link {
    background: none;
    font-size: 1rem;
  }

  &.small {
    font-size: .8rem;
  }

  &.wide {
    min-width: 200px;
    padding: 14px 24px;
  }
}

.code-toolbar {
  margin-bottom: 20px;

  .toolbar-item a {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 8px;
    margin-bottom: 5px;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid transparent;
    appearance: none;
    cursor: pointer;
    outline: none;
    // Default
    background: $light-background-secondary;

    @media (prefers-color-scheme: dark) {
      background: $dark-background-secondary;
      color: inherit;
    }
  
    @media (prefers-color-scheme: light) {
      background: $light-background-secondary;
    }
  
    [data-theme=dark] & {
      background: $dark-background-secondary;
      color: inherit;
    }
  
    [data-theme=light] & {
      background: $light-background-secondary;
    }
  }
}
